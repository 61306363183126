import React from 'react';
import CuerpoPagina from './components/CuerpoPagina';

function App() {
    return (
        <div className='App'>
            <CuerpoPagina/>
        </div>        
        
    );
}

export default App;