import React from 'react';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { FondoPagina, FondoPaginaMovil } from './Imagenes';
import "./ContactUs.css";
import {isTablet, isDesktop} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const ContactForm = () => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState(null);
    const sendEmail = (e) => {
      e.persist();
      e.preventDefault();
      setIsSubmitting(true);
      emailjs
        .sendForm(
          'service_lh77bbe',
          'template_w7ga3vh',
          e.target,
          'ikYrCn5Vt2XPXSv0W'
        )
        .then(
          (result) => {
            setStateMessage('Message sent!');
            setIsSubmitting(false);
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); // hide message after 5 seconds
          },
          (error) => {
            setStateMessage('Something went wrong, please try again later');
            setIsSubmitting(false);
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); // hide message after 5 seconds
          }
        );
      
      // Clears the form after sending the email
      e.target.reset();
    };
    return isTablet || isDesktop ? (
        <div className="PaginaContactoDiv">
             <div className="PaginaContactoContenido"><h2>{t("Contacto.Contactanos")}</h2>
                <FondoPagina/>
            </div>
            <form onSubmit={sendEmail} required>
            <input type="text" name="user_name" placeholder={t("Contacto.Nombre")} required/>
            <input type="email" name="user_email" placeholder={t("Contacto.Correo")} required/>
            <input type="text" name="user_phone" placeholder={t("Contacto.Telefono")} pattern="[0-9]{10}" title={t("Contacto.TelefonoRegla")} required/>
            <label>{t("Contacto.MensajeLetrero")}</label>
            <textarea name="message" placeholder={t("Contacto.Mensaje")} required/>
            <input type="submit" value={t("Contacto.Enviar")} className='BotonEnviar' disabled={isSubmitting} />
                {stateMessage && <p>{stateMessage}</p>}
            </form>
        </div>

    ):(<div className="PaginaContactoDiv">
    <div className="PaginaContactoContenidoMovil"><h2>{t("Contacto.Contactanos")}</h2>
       <FondoPaginaMovil/>
   </div>
   <form onSubmit={sendEmail} required>
            <input type="text" name="user_name" placeholder={t("Contacto.Nombre")} required/>
            <input type="email" name="user_email" placeholder={t("Contacto.Correo")} required/>
            <input type="text" name="user_phone" placeholder={t("Contacto.Telefono")} pattern="[0-9]{10}" title={t("Contacto.TelefonoRegla")} required/>
            <label>{t("Contacto.MensajeLetrero")}</label>
            <textarea name="message" placeholder={t("Contacto.Mensaje")} required/>
            <input type="submit" value={t("Contacto.Enviar")} className='BotonEnviar' disabled={isSubmitting} />
                {stateMessage && <p>{stateMessage}</p>}
            </form>
</div>)
  };