import React from 'react';
import "./Avaluo.css";
import {FondoPagina, FondoPaginaMovil, SujetoAvaluoMovil, SujetoAvaluo, Casa1, Casa2, Casa3, Casa4, Casa5, Casa6, Casa11, Casa12, Casa13, Casa14, Casa15, Casa16, Casa21, Casa22, Casa23, Casa24, Casa25, Casa26, Casa31, Casa32, Casa33, Casa34, Casa36, Casa35, CasaMovil1, CasaMovil3, CasaMovil4, CasaMovil2, CasaMovil5, CasaMovil6, CasaMovil7, CasaMovil8, CasaMovil9, CasaMovil10, CasaMovil11, CasaMovil12, CasaMovil13, CasaMovil14, CasaMovil15, CasaMovil16, CasaMovil17, CasaMovil18} from './Imagenes';
import {isTablet, isDesktop} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const Avaluo = () => {
  const { t } = useTranslation();
  return isTablet || isDesktop ? (
    <div className="PaginaAvaluoDiv">       
        <div className="PaginaAvaluoContenido">
          <div className="LetreroAvaluo"><h2>{t("AvaluoLetrero")}</h2></div>
            <SujetoAvaluo/>
            <FondoPagina/>
            <div className="AvaluoCasas">
              <Casa1/>
              <Casa2/>
              <Casa3/>
              <Casa4/>
              <Casa5/>
              <Casa6/>
              <Casa11/>
              <Casa12/>
              <Casa13/>
              <Casa14/>
              <Casa15/>
              <Casa16/>
              <Casa21/>
              <Casa22/>
              <Casa23/>
              <Casa24/>
              <Casa25/>
              <Casa26/>
              <Casa31/>
              <Casa32/>
              <Casa33/>
              <Casa34/>
              <Casa35/>
              <Casa36/>
            </div>
        </div>
    </div>
  ):(
    <div className="PaginaAvaluoDiv">       
        <div className="PaginaAvaluoContenido">
        <div className="LetreroAvaluo" id="AvaluoLetrero"><h2>{t("AvaluoLetrero")}</h2></div>
            <FondoPaginaMovil/>
            <SujetoAvaluoMovil/>
            <div className="AvaluoCasasMovil">
              <div className="firstColumnCasas">
              <CasaMovil1/>
              <CasaMovil2/>
              <CasaMovil3/>
              <CasaMovil4/>
              <CasaMovil5/>
              <CasaMovil6/>
              </div>
              <div className="secondColumnCasas">
              <CasaMovil7/>
              <CasaMovil8/>
              <CasaMovil9/>
              <CasaMovil10/>
              <CasaMovil11/>
              <CasaMovil12/>
              </div>
              <div className="thirdColumnCasas">
              <CasaMovil13/>
              <CasaMovil14/>
              <CasaMovil15/>
              <CasaMovil16/>
              <CasaMovil17/>
              <CasaMovil18/>
              </div>
            </div>
        </div>
    </div>
  )
}
