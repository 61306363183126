import React from 'react';
import './PiePagina.css';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';

export const PiePagina = () => {
  const { t } = useTranslation();
  return (
    <footer className="PiePagina"> 
        <em>{t("Derechos")}</em>
        <em className='Traduccion'>{t("Idioma")}: </em>
        <LanguageSelector/>
    </footer>

  )
}
