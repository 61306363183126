import React from 'react';
import "./Imagenes.css";
import sumaGIF from "../resources/imagenes/sumanivel.gif";
import sumaLogo from "../resources/imagenes/sumanivelLogo.png";
import sumaTitulo from "../resources/imagenes/sumanivelTitulo.png";
import sumaAvaluoContent from "../resources/imagenes/Avaluo.png";
import fondo from "../resources/imagenes/fondo.jpg";
import fondoMovil from "../resources/imagenes/fondoMovil.png";
import sujetoAvaluo from "../resources/imagenes/sujetosueter.png";
import sujetoAvaluoMovil from "../resources/imagenes/sujeto.png";
import inicioMovil from "../resources/imagenes/isologo.png";
import inicioMovilLogo from "../resources/imagenes/logo.png";
import casa1 from "../resources/imagenes/casa-1.png";
import casa2 from "../resources/imagenes/casa-2.png";
import casa3 from "../resources/imagenes/casa-3.png";
import casa4 from "../resources/imagenes/casa-4.png";
import casa5 from "../resources/imagenes/casa-5.png";
import casa6 from "../resources/imagenes/casa-6.png";
import casa7 from "../resources/imagenes/casa-7.png";
import casa8 from "../resources/imagenes/casa-8.png";
import casa9 from "../resources/imagenes/casa-9.png";
import casa10 from "../resources/imagenes/casa-10.png";
import casa11 from "../resources/imagenes/casa-11.png";
import casa12 from "../resources/imagenes/casa-12.png";
import casa13 from "../resources/imagenes/casa-13.png";
import casa14 from "../resources/imagenes/casa-14.png";
import casa15 from "../resources/imagenes/casa-15.png";
import casa16 from "../resources/imagenes/casa-16.png";
import casa17 from "../resources/imagenes/casa-17.png";
import casa18 from "../resources/imagenes/casa-18.png";
import casa19 from "../resources/imagenes/casa-19.png";
import casa20 from "../resources/imagenes/casa-20.png";
import casa21 from "../resources/imagenes/casa-21.png";
import casa22 from "../resources/imagenes/casa-22.png";
import casa23 from "../resources/imagenes/casa-23.png";
import casa24 from "../resources/imagenes/casa-24.png";
import casa25 from "../resources/imagenes/casa-25.png";
import casa26 from "../resources/imagenes/casa-26.png";
import casa27 from "../resources/imagenes/casa-27.png";
import casa28 from "../resources/imagenes/casa-28.png";
import casa29 from "../resources/imagenes/casa-29.png";
import casa30 from "../resources/imagenes/casa-30.png";
import casa31 from "../resources/imagenes/casa-31.png";
import casa32 from "../resources/imagenes/casa-32.png";
import casa33 from "../resources/imagenes/casa-33.png";
import casa34 from "../resources/imagenes/casa-34.png";
import casa35 from "../resources/imagenes/casa-35.png";
import casa36 from "../resources/imagenes/casa-36.png";
import casaMovil1 from "../resources/imagenes/house1.png";
import casaMovil2 from "../resources/imagenes/house2.png";
import casaMovil3 from "../resources/imagenes/house3.png";
import casaMovil4 from "../resources/imagenes/house4.png";
import casaMovil5 from "../resources/imagenes/house5.png";
import casaMovil6 from "../resources/imagenes/house6.png";
import casaMovil7 from "../resources/imagenes/house7.png";
import casaMovil8 from "../resources/imagenes/house8.png";
import casaMovil9 from "../resources/imagenes/house9.png";
import casaMovil10 from "../resources/imagenes/house10.png";
import casaMovil11 from "../resources/imagenes/house11.png";
import casaMovil12 from "../resources/imagenes/house12.png";
import casaMovil13 from "../resources/imagenes/house13.png";
import casaMovil14 from "../resources/imagenes/house14.png";
import casaMovil15 from "../resources/imagenes/house15.png";
import casaMovil16 from "../resources/imagenes/house16.png";
import casaMovil17 from "../resources/imagenes/house17.png";
import casaMovil18 from "../resources/imagenes/house18.png";
import levantamiento1 from "../resources/imagenes/croquiscad1.png";
import levantamiento2 from "../resources/imagenes/mano_dibujo_3.png";
import levantamiento3 from "../resources/imagenes/croquiscad3.png";
import asesoria1 from "../resources/imagenes/opiniondevalor1.png";
import asesoria2 from "../resources/imagenes/opiniondevalor5.png";
import asesoria3 from "../resources/imagenes/opiniondevalor4.png";
import opinionBalance from "../resources/imagenes/balance_one.png";
import opinionBalanceGif from "../resources/imagenes/Balance.gif";

export const BalanceGIF = () => {
  return (
    <div className="BalanceGIF">
        <img src={opinionBalanceGif} alt="BalanceGIF va aqui"></img>
    </div>
    
  )
}

export const Balance = () => {
  return (
    <div className="Balance">
        <img src={opinionBalance} alt="Balance va aqui"></img>
    </div>
    
  )
}

export const SumanivelGIF = () => {
  return (
    <div className="SumanivelGIF">
        <img src={sumaGIF} alt="GIF va aqui"></img>
    </div>
    
  )
}

export const SumanivelLogo = () => {
    return (
      <div className="SumanivelLogo">
          <img src={sumaLogo} alt="Logo va aqui"></img>
      </div>
      
    )
  }

export const SumanivelTitulo = () => {
    return (
      <div className="SumanivelTitulo">
          <img src={sumaTitulo} alt="Titulo va aqui"></img>
      </div>
      
    )
  }

  export const SumanivelAvaluoContent = () => {
    return (
      <div className="SumanivelAvaluoContent">
          <img src={sumaAvaluoContent} alt="Avaluo va aqui"></img>
      </div>
      
    )
  }


  export const FondoPagina = () => {
    return (
      <div className="FondoPagina">
          <img src={fondo} alt="Fondo va aqui"></img>
      </div>
      
    )
  }

  export const FondoPaginaMovil = () => {
    return (
      <div className="FondoPaginaMovil">
          <img src={fondoMovil} alt="Fondo Movil va aqui"></img>
      </div>
      
    )
  }
  

  export const SujetoAvaluo = () => {
    return (
      <div className="SujetoAvaluo">
          <img src={sujetoAvaluo} alt="Sujeto va aqui"></img>
      </div>
 
    )
  }


  export const SujetoAvaluoMovil = () => {
    return (
      <div className="SujetoAvaluoMovil" id="SujetoMovil">
          <img src={sujetoAvaluoMovil} alt="Sujeto Movil va aqui"></img>
      </div>
 
    )
  }

  export const InicioMovil = () => {
    return (
      <div className="InicioMovil">
          <img className="InicioTexto" src={inicioMovil} alt="Inicio va aqui" href="../resources/imagenes/isologo.png"></img>
          <img className="InicioLogo" src={inicioMovilLogo} alt="Logo va aqui" href="../resources/imagenes/logo.png"></img>
      </div>
 
    )
  }

  export const Casa1 = () => {
    return (
      <div className="casa1">
          <img src={casa1} alt="Casa1 va aqui"></img>
      </div>
    )
  }


  export const Casa2 = () => {
    return (
      <div className="casa2">
          <img src={casa2} alt="Casa2 va aqui"></img>
      </div>
    )
  }

  export const Casa3 = () => {
    return (
      <div className="casa3">
          <img src={casa3} alt="Casa3 va aqui"></img>
      </div>
    )
  }

  export const Casa4 = () => {
    return (
      <div className="casa4">
          <img src={casa4} alt="Casa4 va aqui"></img>
      </div>
    )
  }

  export const Casa5 = () => {
    return (
      <div className="casa5">
          <img src={casa5} alt="Casa5 va aqui"></img>
      </div>
    )
  }

  export const Casa6 = () => {
    return (
      <div className="casa6">
          <img src={casa6} alt="Casa6 va aqui"></img>
      </div>
    )
  }

  export const Casa7 = () => {
    return (
      <div className="casa7">
          <img src={casa7} alt="Casa7 va aqui"></img>
      </div>
    )
  }

  export const Casa8 = () => {
    return (
      <div className="casa8">
          <img src={casa8} alt="Casa8 va aqui"></img>
      </div>
    )
  }

  export const Casa9 = () => {
    return (
      <div className="casa9">
          <img src={casa9} alt="Casa9 va aqui"></img>
      </div>
    )
  }


  export const Casa10 = () => {
    return (
      <div className="casa10">
          <img src={casa10} alt="Casa10 va aqui"></img>
      </div>
    )
  }


  export const Casa11 = () => {
    return (
      <div className="casa11">
          <img src={casa11} alt="Casa11 va aqui"></img>
      </div>
    )
  }


  export const Casa12 = () => {
    return (
      <div className="casa12">
          <img src={casa12} alt="Casa12 va aqui"></img>
      </div>
    )
  }


  export const Casa13 = () => {
    return (
      <div className="casa13">
          <img src={casa13} alt="Casa13 va aqui"></img>
      </div>
    )
  }

  export const Casa14 = () => {
    return (
      <div className="casa14">
          <img src={casa14} alt="Casa14 va aqui"></img>
      </div>
    )
  }

  export const Casa15 = () => {
    return (
      <div className="casa15">
          <img src={casa15} alt="Casa15 va aqui"></img>
      </div>
    )
  }

  export const Casa16 = () => {
    return (
      <div className="casa16">
          <img src={casa16} alt="Casa16 va aqui"></img>
      </div>
    )
  }

  export const Casa17 = () => {
    return (
      <div className="casa17">
          <img src={casa17} alt="Casa17 va aqui"></img>
      </div>
    )
  }

  export const Casa18 = () => {
    return (
      <div className="casa18">
          <img src={casa18} alt="Casa18 va aqui"></img>
      </div>
    )
  }

  export const Casa19 = () => {
    return (
      <div className="casa19">
          <img src={casa19} alt="Casa19 va aqui"></img>
      </div>
    )
  }

  export const Casa20 = () => {
    return (
      <div className="casa20">
          <img src={casa20} alt="Casa20 va aqui"></img>
      </div>
    )
  }

  export const Casa21 = () => {
    return (
      <div className="casa21">
          <img src={casa21} alt="Casa21 va aqui"></img>
      </div>
    )
  }

  export const Casa22 = () => {
    return (
      <div className="casa22">
          <img src={casa22} alt="Casa22 va aqui"></img>
      </div>
    )
  }

  export const Casa23 = () => {
    return (
      <div className="casa23">
          <img src={casa23} alt="Casa23 va aqui"></img>
      </div>
    )
  }

  export const Casa24 = () => {
    return (
      <div className="casa24">
          <img src={casa24} alt="Casa24 va aqui"></img>
      </div>
    )
  }

  export const Casa25 = () => {
    return (
      <div className="casa25">
          <img src={casa25} alt="Casa25 va aqui"></img>
      </div>
    )
  }

  export const Casa26 = () => {
    return (
      <div className="casa26">
          <img src={casa26} alt="Casa26 va aqui"></img>
      </div>
    )
  }

  export const Casa27 = () => {
    return (
      <div className="casa27">
          <img src={casa27} alt="Casa27 va aqui"></img>
      </div>
    )
  }

  export const Casa28 = () => {
    return (
      <div className="casa28">
          <img src={casa28} alt="Casa28 va aqui"></img>
      </div>
    )
  }

  export const Casa29 = () => {
    return (
      <div className="casa29">
          <img src={casa29} alt="Casa29 va aqui"></img>
      </div>
    )
  }

  export const Casa30 = () => {
    return (
      <div className="casa30">
          <img src={casa30} alt="Casa30 va aqui"></img>
      </div>
    )
  }

  export const Casa31 = () => {
    return (
      <div className="casa31">
          <img src={casa31} alt="Casa31 va aqui"></img>
      </div>
    )
  }

  export const Casa32 = () => {
    return (
      <div className="casa32">
          <img src={casa32} alt="Casa32 va aqui"></img>
      </div>
    )
  }

  export const Casa33 = () => {
    return (
      <div className="casa33">
          <img src={casa33} alt="Casa33 va aqui"></img>
      </div>
    )
  }

  export const Casa34 = () => {
    return (
      <div className="casa34">
          <img src={casa34} alt="Casa34 va aqui"></img>
      </div>
    )
  }
 
  export const Casa35 = () => {
    return (
      <div className="casa35">
          <img src={casa35} alt="Casa35 va aqui"></img>
      </div>
    )
  }

  export const Casa36 = () => {
    return (
      <div className="casa36">
          <img src={casa36} alt="Casa36 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil1 = () => {
    return (
      <div className="casaMovil1">
          <img src={casaMovil1} alt="CasaMovil1 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil2 = () => {
    return (
      <div className="casaMovil2">
          <img src={casaMovil2} alt="CasaMovil2 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil3 = () => {
    return (
      <div className="casaMovil3">
          <img src={casaMovil3} alt="CasaMovil3 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil4 = () => {
    return (
      <div className="casaMovil4">
          <img src={casaMovil4} alt="CasaMovil4 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil5 = () => {
    return (
      <div className="casaMovil5">
          <img src={casaMovil5} alt="CasaMovil5 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil6 = () => {
    return (
      <div className="casaMovil6">
          <img src={casaMovil6} alt="CasaMovil6 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil7 = () => {
    return (
      <div className="casaMovil7">
          <img src={casaMovil7} alt="CasaMovil7 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil8 = () => {
    return (
      <div className="casaMovil8">
          <img src={casaMovil8} alt="CasaMovil8 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil9 = () => {
    return (
      <div className="casaMovil9">
          <img src={casaMovil9} alt="CasaMovil9 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil10 = () => {
    return (
      <div className="casaMovil10">
          <img src={casaMovil10} alt="CasaMovil10 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil11 = () => {
    return (
      <div className="casaMovil11">
          <img src={casaMovil11} alt="CasaMovil11 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil12 = () => {
    return (
      <div className="casaMovil12">
          <img src={casaMovil12} alt="CasaMovil12 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil13 = () => {
    return (
      <div className="casaMovil13">
          <img src={casaMovil13} alt="CasaMovil13 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil14 = () => {
    return (
      <div className="casaMovil14">
          <img src={casaMovil14} alt="CasaMovil14 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil15 = () => {
    return (
      <div className="casaMovil15">
          <img src={casaMovil15} alt="CasaMovil15 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil16 = () => {
    return (
      <div className="casaMovil16">
          <img src={casaMovil16} alt="CasaMovil16 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil17 = () => {
    return (
      <div className="casaMovil17">
          <img src={casaMovil17} alt="CasaMovil17 va aqui"></img>
      </div>
    )
  }

  export const CasaMovil18 = () => {
    return (
      <div className="casaMovil18">
          <img src={casaMovil18} alt="CasaMovil18 va aqui"></img>
      </div>
    )
  }

  export const LevantamientoCroquis1 = () => {
    return (
      <div className="LevantamientoCroquis1">
          <img className="LevantamientoCroquis1" src={levantamiento1} alt="levantamiento1 va aqui"></img>
      </div>
    )
  }

  export const LevantamientoCroquis2 = () => {
    return (
      <div className="LevantamientoCroquis2">
          <img className="LevantamientoCroquis2" src={levantamiento2} alt="levantamiento2 va aqui"></img>
      </div>
    )
  }

  export const LevantamientoCroquis3 = () => {
    return (
      <div className="LevantamientoCroquis3">
          <img className="LevantamientoCroquis3" src={levantamiento3} alt="levantamiento3 va aqui"></img>
      </div>
    )
  }

  export const AsesoriaInmo1 = () => {
    return (
      <div className="AsesoriaInmo1">
          <img className="AsesoriaInmo1" src={asesoria1} alt="asesoria1 va aqui"></img>
      </div>
    )
  }

  export const AsesoriaInmo2 = () => {
    return (
      <div className="AsesoriaInmo2">
          <img className="AsesoriaInmo2" src={asesoria2} alt="asesoria2 va aqui"></img>
      </div>
    )
  }

  export const AsesoriaInmo3 = () => {
    return (
      <div className="AsesoriaInmo3">
          <img className="AsesoriaInmo3" src={asesoria3} alt="asesoria3 va aqui"></img>
      </div>
    )
  }