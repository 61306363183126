import React from 'react';
import "./AsesoriaInmo.css";
import { FondoPagina, FondoPaginaMovil, AsesoriaInmo1, AsesoriaInmo2, AsesoriaInmo3} from './Imagenes';
import {isTablet, isDesktop} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const AsesoriaInmo = () => {
  const { t } = useTranslation();
  return isTablet || isDesktop ? (
    <div className="PaginaAsesoriaInmoDiv">       
        <div className="PaginaAsesoriaInmoDesktop">
          <div className="LetreroAsesoria"><h2>{t("AsesoriaLetrero")}</h2></div>
          <AsesoriaInmo1/>
          <AsesoriaInmo2/>
          <AsesoriaInmo3/>
            <FondoPagina/>
        </div>
    </div>
    
  ): (
    <div className="PaginaAsesoriaInmoDiv">       
    <div className="PaginaAsesoriaInmo">
      <div className="LetreroAsesoria" id="AsesoriaLetrero"><h2>{t("AsesoriaLetrero")}</h2></div>
      <div id="SujetoAvaluoAsesoria"> 
      <AsesoriaInmo1/>
      <AsesoriaInmo2/>
      <AsesoriaInmo3/>
      </div>
        <FondoPaginaMovil/>
    </div>
  </div>
  )
}
