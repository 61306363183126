import React from 'react';
import './MenuNavegacion.css';
import { AsesoriaInmo1, Balance, LevantamientoCroquis1, SujetoAvaluoMovil, SumanivelLogo } from './Imagenes';
import { Avaluo } from './Avaluo';
import { OpinionValor } from './OpinionValor';
import { AsesoriaInmo } from './AsesoriaInmo';
import { Levantamiento } from './Levantamiento';
import { PaginaInicio } from './PaginaInicio';
import { PiePagina } from './PiePagina';
import logoWhatsappNaranja from "../resources/imagenes/icono_whatsapp.png";
import logoWhatsappBlanco from "../resources/imagenes/icono_whatsapp_blanco.png";
import contactLogoBlanco from "../resources/imagenes/contactus.png";
import contactLogoNaranja from "../resources/imagenes/envelope_orange.png";
import { ContactForm } from './ContactUs';
import { withTranslation } from 'react-i18next';

class MenuNavegacion extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      left: "-50%",
      leftPaginaDerecha: "0",
      leftPaginaIzquierda: "-55%",
      click: false,
      hoverAvaluo: false,
      hoverGeneral: false,
      visibilityLogoIzquierda: "hidden",
      opacityLogoIzquierda: "0",
      visibilityPrev: "visible",
      opacityPrev: "1",
    }
  }

  handleMouseEnterAvaluo = (e) => {
    this.handleMouseEnter(e);
    this.setState({
      hoverAvaluo: true,
      hoverGeneral: false,
      leftInicioContenido: "40vh",
    })
  }


  handleMouseEnter = (e) => {
    if(e.target.className === "BotonInicio"){
      this.setState({
        left: e.target.getBoundingClientRect().x - (window.innerWidth*0.0125),
        leftPaginaDerecha: "0",
        leftPaginaIzquierda: "-55%",
        hoverGeneral: true,
        leftInicioContenido: "50vh",
        leftInicioContenidoIzquierda: "-50vh",
      });
    } else if (e.target.className === "BotonOpinion"){
      this.setState({
        leftInicioContenido: "10vh",
        leftInicioContenidoIzquierda: "-50vh",
        left: e.target.getBoundingClientRect().x - (window.innerWidth*0.0125),
        leftPaginaDerecha: e.target.getBoundingClientRect().x +3,
        leftPaginaIzquierda: e.target.getBoundingClientRect().x - (window.innerWidth*0.80),
        hoverGeneral: true,
      });
    }else if (e.target.className === "BotonAsesor"){
        this.setState({
          leftInicioContenido: "-10vh",
          leftInicioContenidoIzquierda: "-50vh",
          left: e.target.getBoundingClientRect().x - (window.innerWidth*0.0125),
          leftPaginaDerecha: e.target.getBoundingClientRect().x +3,
          leftPaginaIzquierda: e.target.getBoundingClientRect().x - (window.innerWidth*0.80),
          hoverGeneral: true,
        });
      }else if (e.target.className === "LastButton"){
        this.setState({
          leftInicioContenido: "80vh",
          leftInicioContenidoIzquierda: "10vh",
          visibilityLogoIzquierda: "visible",
          opacityLogoIzquierda: "1",
          left: e.target.getBoundingClientRect().x - (window.innerWidth*0.0125),
          leftPaginaDerecha: e.target.getBoundingClientRect().x +3,
          leftPaginaIzquierda: e.target.getBoundingClientRect().x - (window.innerWidth*0.80),
          hoverGeneral: true,
        });
      }else{
      this.setState({
      left: e.target.getBoundingClientRect().x - (window.innerWidth*0.0125),
      leftPaginaDerecha: e.target.getBoundingClientRect().x +3,
      leftPaginaIzquierda: e.target.getBoundingClientRect().x - (window.innerWidth*0.80),
      hoverGeneral: true,
    });
    } if(this.state.click){
      this.setState({
        leftPaginaDerecha: "200%",
        leftPaginaIzquierda: "-200%",
        hoverGeneral: true,
      });
    }
  }

  handleMouseLeaveAvaluo = (e) => {
    this.setState({
      hoverGeneral: false,
    });
  }

  handleMouseLeave = (e) => {
    if(e.target.className === "BotonInicio") {     
        this.setState({
        hoverAvaluo: false,
        hoverGeneral: false,
        leftInicioContenido: "50vh",
      });}
  }

  handleClickButonColor = (e) => {
    this.setState({
      background: "#f75c4a",
      color: "white",
    });
  }

  handleClick = (e) => {
    this.setState({
      opacityPrev: "0",
      visibilityPrev: "hidden",
    });
    if(e.target.className === "BotonInicio"){
      this.handleMouseEnter(e);
      this.setState({
        opacityAvaluo: "0",
        visibilityAvaluo: "hidden",
        opacityOpinion: "0",
        visibilityOpinion: "hidden",
        opacityAsesor: "0",
        visibilityAsesor: "hidden",
        opacityLev: "0",
        visibilityLev: "hidden",
        click: false,
        opacityCon: "0",
        visibilityCon: "hidden",
        opacityConButton: "1",
        visibilityConButton: "visible",
      });
      window.location.reload(false);
    }else if (e.target.className === "BotonAvaluo"){
      this.handleClickButonColor(e);
      this.setState({
        leftPaginaDerecha: "200%",
        leftPaginaIzquierda: "-200%",
        click: true,
        opacityAvaluo: "1",
        visibilityAvaluo: "visible",
        opacityOpinion: "0",
        visibilityOpinion: "hidden",
        opacityAsesor: "0",
        visibilityAsesor: "hidden",
        opacityLev: "0",
        visibilityLev: "hidden",
        opacityCon: "0",
        visibilityCon: "hidden",
        opacityConButton: "1",
        visibilityConButton: "visible",
      });
    }else if (e.target.className === "BotonOpinion"){
      this.handleClickButonColor(e);
      this.setState({
        leftPaginaDerecha: "200%",
        leftPaginaIzquierda: "-200%",
        click: true,
        opacityAvaluo: "0",
        visibilityAvaluo: "hidden",
        opacityOpinion: "1",
        visibilityOpinion: "visible",
        opacityAsesor: "0",
        visibilityAsesor: "hidden",
        opacityLev: "0",
        visibilityLev: "hidden",
        opacityCon: "0",
        visibilityCon: "hidden",
        opacityConButton: "1",
        visibilityConButton: "visible",
      });
    }else if (e.target.className === "BotonAsesor"){
      this.handleClickButonColor(e);
      this.setState({
        leftPaginaDerecha: "200%",
        leftPaginaIzquierda: "-200%",
        click: true,
        opacityAvaluo: "0",
        visibilityAvaluo: "hidden",
        opacityOpinion: "0",
        visibilityOpinion: "hidden",
        opacityAsesor: "1",
        visibilityAsesor: "visible",
        opacityLev: "0",
        visibilityLev: "hidden",
        opacityCon: "0",
        visibilityCon: "hidden",
        opacityConButton: "1",
        visibilityConButton: "visible",
      });
    }else if (e.target.className === "LastButton"){
      this.handleClickButonColor(e);
      this.setState({
        leftPaginaDerecha: "200%",
        leftPaginaIzquierda: "-200%",
        click: true,
        opacityAvaluo: "0",
        visibilityAvaluo: "hidden",
        opacityOpinion: "0",
        visibilityOpinion: "hidden",
        opacityAsesor: "0",
        visibilityAsesor: "hidden",
        opacityLev: "1",
        visibilityLev: "visible",
        opacityCon: "0",
        visibilityCon: "hidden",
        opacityConButton: "1",
        visibilityConButton: "visible",
      });
    }else if (e.target.className === "Contact" || "ContactIMG" || "ContactUs"){
      this.handleClickButonColor(e);
      this.setState({
        leftPaginaDerecha: "200%",
        leftPaginaIzquierda: "-200%",
        click: true,
        opacityAvaluo: "0",
        visibilityAvaluo: "hidden",
        opacityOpinion: "0",
        visibilityOpinion: "hidden",
        opacityAsesor: "0",
        visibilityAsesor: "hidden",
        opacityLev: "0",
        visibilityLev: "hidden",
        opacityCon: "1",
        visibilityCon: "visible",
        opacityConButton: "0",
        visibilityConButton: "hidden",
      });
    }

  }

  render() {

    return(
      <div className="MenuNavegacion">
          <div className="MenuButtons">
          <a className="BotonInicio" href="#home" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleClick} style={{color: this.state.background, borderLeftColor: this.state.background, backgroundColor: this.state.color}}>Home</a>

          <a className="BotonAvaluo" href="#avaluo" onMouseOver={this.handleMouseEnterAvaluo} onMouseLeave={this.handleMouseLeaveAvaluo} onClick={this.handleClick} style={{color: this.state.background, borderLeftColor: this.state.background, backgroundColor: this.state.color}}>{this.props.t("Avaluo")} </a>

          <a className="BotonOpinion" href="#opiniondevalor" onMouseOver={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleClick} style={{color: this.state.background, borderLeftColor: this.state.background, backgroundColor: this.state.color}}>{this.props.t("OpinionValor")}</a>

          <a className="BotonAsesor" href="#asesoriainmobiliaria" onMouseOver={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleClick} style={{color: this.state.background, borderLeftColor: this.state.background, borderRightColor: this.state.background, backgroundColor: this.state.color}}>{this.props.t("Asesoria")}</a>

          <a className='LastButton' href="#levantamientoarquitectonico" onMouseOver={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleClick} style={{color: this.state.background, borderRightColor: this.state.background, borderLeftColor: this.state.background, backgroundColor: this.state.color}}>{this.props.t("Levantamiento")}</a>

          <div className="stripe" style={{ left: this.state.left, background: this.state.background }}/>
          
          </div>

         

          <div className='PaginaInicioContenido'>
            <div className='WhatsappSection'> 
              <a className='Whatsapp' href='https://wa.me/message/XJ6DT2VHBLPRM1' target="_blank" rel="noopener noreferrer"><img src={((this.state.hoverAvaluo) && (!this.state.hoverGeneral)) || (this.state.click) ? logoWhatsappNaranja : logoWhatsappBlanco} alt="Whatsapp Logo va aqui"></img></a>
              <h2> {this.props.t("WhatsappDesktop")} </h2>
            </div>

            <div className='ContactUs' style={{opacity: this.state.opacityConButton, visibility: this.state.visibilityConButton}}> 
              <h2> {this.props.t("Contactanos")} </h2>
              <a className='Contact' href='#contactus' onClick={this.handleClick}><img className='ContactIMG' onClick={this.handleClick} src={(this.state.click) ? contactLogoNaranja : contactLogoBlanco} alt="ContactUs Logo va aqui"></img></a>
            </div>

            <div className='Previsualizaciones' style={{ opacity: this.state.opacityPrev, visibility: this.state.visibilityPrev}}>
              <div className='PrevAvaluo'>
                <SujetoAvaluoMovil></SujetoAvaluoMovil>
              </div>
              <div className='PrevOpinion'>
                <Balance></Balance>
              </div>
              <div className='PrevAsesoria'>
                <AsesoriaInmo1></AsesoriaInmo1>
              </div>
              <div className='PrevLevantamiento'>
                <LevantamientoCroquis1/>
              </div>
            </div>

            <div className='PaginaAvaluo' style={{opacity: this.state.opacityAvaluo, visibility: this.state.visibilityAvaluo}}>
                <Avaluo/>
            </div>
            <div className='PaginaOpinion' style={{opacity: this.state.opacityOpinion, visibility: this.state.visibilityOpinion}}>
                <OpinionValor/>
            </div>
            <div className='PaginaAsesoria' style={{opacity: this.state.opacityAsesor, visibility: this.state.visibilityAsesor}}>
                <AsesoriaInmo/>
            </div>
            <div className='PaginaLev' style={{opacity: this.state.opacityLev, visibility: this.state.visibilityLev}}>
                <Levantamiento/>
            </div>

            <div className='PaginaContacto' style={{opacity: this.state.opacityCon, visibility: this.state.visibilityCon}}>
                <ContactForm/>
            </div>

            <div className="PaginaInicioDerecha" style={{ left: this.state.leftPaginaDerecha}}>
                <div className='ContenidoPaginaInicioDerecha' style={{ left: this.state.leftInicioContenido }}>
                  <PaginaInicio/>
                  <SumanivelLogo/>
                </div>
            </div>
            <div className="PaginaInicioIzquierda" style={{ left: this.state.leftPaginaIzquierda}}>
            <div className='ContenidoPaginaInicioIzquierda' style={{ opacity: this.state.opacityLogoIzquierda , visibility:this.state.visibilityLogoIzquierda , left:this.state.leftInicioContenidoIzquierda }}>
                <PaginaInicio/>
                <SumanivelLogo/>
              </div>
            </div>
          </div>
          <PiePagina/>
      </div>
       )
  }
}

export default withTranslation()(MenuNavegacion);