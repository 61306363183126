import React from "react";
import { InicioMovil} from "./Imagenes";
import {isTablet, isDesktop} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const PaginaInicio = () => {
    const { t } = useTranslation();
    return  isTablet || isDesktop ? (
        <div className='PaginaInicio'>
            <div className="LetreroInicio"><h2>{t("Bienvenida")}</h2></div>
        </div>      
    ): (<div className='PaginaInicio'>
    <div className="LetreroInicio" id="InicioMovilLetrero"><h1>{t("BienvenidaMovil")}</h1></div>
        <InicioMovil/>
    </div>)
}