import React from 'react';
import "./Levantamiento.css";
import { FondoPagina, FondoPaginaMovil, LevantamientoCroquis1, LevantamientoCroquis2, LevantamientoCroquis3} from './Imagenes';
import {isTablet, isDesktop} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const Levantamiento = () => {
  const { t } = useTranslation();
  return isTablet || isDesktop ? (
    <div className="PaginaLevantamientoDiv">       
        <div className="PaginaLevantamientoDesktop">
        <div className="LetreroLevantamiento"><h2>{t("LevantamientoLetrero")}</h2></div>
        <LevantamientoCroquis1/>
        <LevantamientoCroquis2/>
        <LevantamientoCroquis3/>
            <FondoPagina/>
        </div>
    </div>
    
  ) : (
    <div className="PaginaLevantamientoDiv">       
    <div className="PaginaLevantamiento">
    <div className="LetreroLevantamiento" id="LevantamientoLetrero"><h2>{t("LevantamientoLetrero")}</h2></div>
    <div id="SujetoAvaluoLevantamiento"> 
      <LevantamientoCroquis1/>
      <LevantamientoCroquis2/>
      <LevantamientoCroquis3/>
    </div>
        <FondoPaginaMovil/>
    </div>
</div>
  )
}
