import React from 'react';
import MenuNavegacion from './MenuNavegacion';
import {isTablet, isMobile} from 'react-device-detect';
import MenuNavegacionMovil from './MenuNavegacionMovil';


export default class CuerpoPagina extends React.Component {
    render() {
      
            return isTablet || isMobile ? 
                (<MenuNavegacionMovil/>):(<MenuNavegacion/>)
    }

}