import React from 'react';
import './MenuNavegacionMovil.css';
import { Avaluo } from './Avaluo';
import { PaginaInicio } from './PaginaInicio';
import logoAvaluoBlanco from "../resources/imagenes/icono_avaluo_blanco.png";
import logoAvaluoNaranja from "../resources/imagenes/icono_avaluo.png";
import logoOpinionBlanco from "../resources/imagenes/icono_opinion_blanco.png";
import logoOpinionNaranja from "../resources/imagenes/icono_opinion.png";
import logoAsesoriaBlanco from "../resources/imagenes/icono_asesoria_blanco.png";
import logoAsesoriaNaranja from "../resources/imagenes/icono_asesoria.png";
import logoLevantamientoBlanco from "../resources/imagenes/icono_sketchacad_blanco.png"
import logoLevantamientoNaranja from "../resources/imagenes/icono_sketchacad.png"
import logoWhatsappBlanco from "../resources/imagenes/icono_whatsapp_blanco.png";
import logoWhatsappNaranja from "../resources/imagenes/icono_whatsapp.png";
import logoHome from "../resources/imagenes/icono_home.png";
import { OpinionValor } from './OpinionValor';
import { AsesoriaInmo } from './AsesoriaInmo';
import { Levantamiento } from './Levantamiento';
import contactLogoBlanco from "../resources/imagenes/contactus.png";
import contactLogoNaranja from "../resources/imagenes/envelope_orange.png";
import { ContactForm } from './ContactUs';
import languageLogoBlanco from '../resources/imagenes/español_english.png';
import languageLogoNaranja from '../resources/imagenes/español_english_ORANGE.png';
import LanguageSelector from './LanguageSelector';
import { withTranslation } from 'react-i18next';


class MenuNavegacionMovil extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      click: false,
      bcHome: "#f75c4a",
      opacityCon: "1",
      visibilityCon: "visible",
    }

  }


  handleClick = (e) => {
    if(e.target.className === "logoAvaluos" || e.target.className === "Avaluos" || e.target.className === "TextoAvaluos" ){
      this.setState({
        topPaginaInicio: "-100vh",
        click: true,
        bcAvaluo: "#f75c4a",
        colorAvaluo: "transparent",
        bcOpinion: "#f75c4a",
        colorOpinion: "transparent",
        bcAsesoria: "#f75c4a",
        colorAsesoria:  "transparent",
        bcLevantamiento: "#f75c4a",
        colorLevantamiento:"transparent",
        bcHome: "#f75c4a",
        colorHome: "transparent",
        bcContacto: "#f75c4a",
        colorContacto: "transparent",
        opacityCon: "1",
        visibilityCon: "visible",
      });
      if(document.getElementById("AvaluoLetrero").className === 'LetreroAvaluo'){
        document.getElementById("AvaluoLetrero").className = 'LetreroAvaluoA';
        document.getElementById("SujetoMovil").className = 'SujetoAvaluoMovilA';
        document.getElementById("InicioMovilLetrero").className = 'LetreroInicioStatic';
        document.getElementById("OpinionLetrero").className = 'LetreroOpinion';
        document.getElementById("AsesoriaLetrero").className = 'LetreroAsesoria';
        document.getElementById("LevantamientoLetrero").className = 'LetreroLevantamiento';
        document.getElementById("SujetoAvaluoOpinion").className = 'SujetoAvaluoMovilOpinion';
        document.getElementById("SujetoAvaluoAsesoria").className = 'SujetoAvaluoMovilAsesoria';
        document.getElementById("SujetoAvaluoLevantamiento").className = 'SujetoAvaluoMovilLevantamiento';
        document.getElementById("LanguageSelection").style.color="#f75c4a";
        document.getElementById("LanguageSelection").style.borderColor="white";
        document.getElementById("LanguageSelection").style.backgroundColor="white";
    }
    }else if (e.target.className === "logoOpinion" || e.target.className === "OpinionValor" || e.target.className === "TextoOpinion"){
      this.setState({
        topPaginaInicio: "-200vh",
        click: true,
        bcAvaluo: "#f75c4a",
        colorAvaluo: "transparent",
        bcOpinion: "#f75c4a",
        colorOpinion: "transparent",
        bcAsesoria: "#f75c4a",
        colorAsesoria:  "transparent",
        bcLevantamiento: "#f75c4a",
        colorLevantamiento: "transparent",
        bcHome: "#f75c4a",
        colorHome: "transparent",
        bcContacto: "#f75c4a",
        colorContacto: "transparent",
        opacityCon: "1",
        visibilityCon: "visible",
      });
      if(document.getElementById("OpinionLetrero").className === 'LetreroOpinion'){
        document.getElementById("AvaluoLetrero").className = 'LetreroAvaluo';
        document.getElementById("SujetoAvaluoOpinion").className = 'SujetoAvaluoMovilOpinionA';
        document.getElementById("SujetoMovil").className = 'SujetoAvaluoMovil';
        document.getElementById("InicioMovilLetrero").className = 'LetreroInicioStatic';
        document.getElementById("OpinionLetrero").className = 'LetreroOpinionA';
        document.getElementById("AsesoriaLetrero").className = 'LetreroAsesoria';
        document.getElementById("LevantamientoLetrero").className = 'LetreroLevantamiento';
        document.getElementById("SujetoAvaluoAsesoria").className = 'SujetoAvaluoMovilAsesoria';
        document.getElementById("SujetoAvaluoLevantamiento").className = 'SujetoAvaluoMovilLevantamiento';
        document.getElementById("LanguageSelection").style.color="#f75c4a";
        document.getElementById("LanguageSelection").style.borderColor="white";
        document.getElementById("LanguageSelection").style.backgroundColor="white";
    }
      
    }else if (e.target.className === "logoAsesoria" || e.target.className === "AsesoriaInmo" || e.target.className === "TextoAsesoria"){
      this.setState({
        topPaginaInicio: "-300vh",
        click: true,
        bcAvaluo: "#f75c4a",
        colorAvaluo: "transparent",
        bcOpinion: "#f75c4a",
        colorOpinion: "transparent",
        bcAsesoria: "#f75c4a",
        colorAsesoria:  "transparent",
        bcLevantamiento: "#f75c4a",
        colorLevantamiento: "transparent",
        bcHome: "#f75c4a",
        colorHome: "transparent",
        bcContacto: "#f75c4a",
        colorContacto: "transparent",
        opacityCon: "1",
        visibilityCon: "visible",
      });

      if(document.getElementById("AsesoriaLetrero").className === 'LetreroAsesoria'){
        document.getElementById("AvaluoLetrero").className = 'LetreroAvaluo';
        document.getElementById("SujetoMovil").className = 'SujetoAvaluoMovil';
        document.getElementById("InicioMovilLetrero").className = 'LetreroInicioStatic';
        document.getElementById("OpinionLetrero").className = 'LetreroOpinion';
        document.getElementById("AsesoriaLetrero").className = 'LetreroAsesoriaA';
        document.getElementById("LevantamientoLetrero").className = 'LetreroLevantamiento';
        document.getElementById("SujetoAvaluoOpinion").className = 'SujetoAvaluoMovilOpinion';
        document.getElementById("SujetoMovil").className = 'SujetoAvaluoMovil';
        document.getElementById("SujetoAvaluoAsesoria").className = 'SujetoAvaluoMovilAsesoriaA';
        document.getElementById("SujetoAvaluoLevantamiento").className = 'SujetoAvaluoMovilLevantamiento';
        document.getElementById("LanguageSelection").style.color="#f75c4a";
        document.getElementById("LanguageSelection").style.borderColor="white";
        document.getElementById("LanguageSelection").style.backgroundColor="white";
    }

    }else if (e.target.className === "logoLevantamiento" || e.target.className === "Levantamientos" || e.target.className === "TextoLevantamiento"){
      this.setState({
        topPaginaInicio: "-400vh",
        click: true,
        bcAvaluo: "#f75c4a",
        colorAvaluo: "transparent",
        bcOpinion: "#f75c4a",
        colorOpinion: "transparent",
        bcAsesoria: "#f75c4a",
        colorAsesoria:  "transparent",
        bcLevantamiento: "#f75c4a",
        colorLevantamiento: "transparent",
        bcHome: "#f75c4a",
        colorHome: "transparent",
        bcContacto: "#f75c4a",
        colorContacto: "transparent",
        opacityCon: "1",
        visibilityCon: "visible",
      });
      if(document.getElementById("LevantamientoLetrero").className === 'LetreroLevantamiento'){
        document.getElementById("AvaluoLetrero").className = 'LetreroAvaluo';
        document.getElementById("SujetoMovil").className = 'SujetoAvaluoMovil';
        document.getElementById("InicioMovilLetrero").className = 'LetreroInicioStatic';
        document.getElementById("OpinionLetrero").className = 'LetreroOpinion';
        document.getElementById("AsesoriaLetrero").className = 'LetreroAsesoria';
        document.getElementById("LevantamientoLetrero").className = 'LetreroLevantamientoA';
        document.getElementById("SujetoAvaluoOpinion").className = 'SujetoAvaluoMovilOpinion';
        document.getElementById("SujetoMovil").className = 'SujetoAvaluoMovil';
        document.getElementById("SujetoAvaluoAsesoria").className = 'SujetoAvaluoMovilAsesoria';
        document.getElementById("SujetoAvaluoLevantamiento").className = 'SujetoAvaluoMovilLevantamientoA';
        document.getElementById("LanguageSelection").style.color="#f75c4a";
        document.getElementById("LanguageSelection").style.borderColor="white";
        document.getElementById("LanguageSelection").style.backgroundColor="white";
    }

    }else if (e.target.className === "logoHome" || e.target.className === "HomeButton" || e.target.className === "TextoHome"){
      this.setState({
        topPaginaInicio: "0vh",
        click: false,
        bcAvaluo: "white",
        colorAvaluo: "#f75c4a",
        bcOpinion: "white",
        colorOpinion: "#f75c4a",
        bcAsesoria: "white",
        colorAsesoria:  "#f75c4a",
        bcLevantamiento: "white",
        colorLevantamiento: "#f75c4a",
        bcHome: "#f75c4a",
        colorHome: "transparent",
        bcContacto: "white",
        colorContacto: "transparent",
        opacityCon: "1",
        visibilityCon: "visible",
      });
      if(document.getElementById("InicioMovilLetrero").className === 'LetreroInicio'){
          document.getElementById("InicioMovilLetrero").className = 'LetreroInicioStatic';
      } else {
          document.getElementById("InicioMovilLetrero").className = 'LetreroInicio';
          document.getElementById("AvaluoLetrero").className = 'LetreroAvaluo';
          document.getElementById("SujetoMovil").className = 'SujetoAvaluoMovil';
          document.getElementById("OpinionLetrero").className = 'LetreroOpinion';
          document.getElementById("AsesoriaLetrero").className = 'LetreroAsesoria';
          document.getElementById("LevantamientoLetrero").className = 'LetreroLevantamiento';
          document.getElementById("SujetoAvaluoOpinion").className = 'SujetoAvaluoMovilOpinion';
          document.getElementById("SujetoAvaluoAsesoria").className = 'SujetoAvaluoMovilAsesoria';
          document.getElementById("SujetoAvaluoLevantamiento").className = 'SujetoAvaluoMovilLevantamiento';
          document.getElementById("LanguageSelection").style.color="white";
          document.getElementById("LanguageSelection").style.borderColor="Transparent";
          document.getElementById("LanguageSelection").style.backgroundColor="Transparent";
      }
    }
    else if (e.target.className === "ContactButton" || e.target.className === "ContactIMG" || e.target.className === "ContactTexto"){
      this.setState({
        topPaginaInicio: "-500vh",
        click: true,
        bcAvaluo: "#f75c4a",
        colorAvaluo: "transparent",
        bcOpinion: "#f75c4a",
        colorOpinion: "transparent",
        bcAsesoria: "#f75c4a",
        colorAsesoria:  "transparent",
        bcLevantamiento: "#f75c4a",
        colorLevantamiento: "transparent",
        bcHome: "#f75c4a",
        colorHome: "transparent",
        bcContacto: "#f75c4a",
        colorContacto: "transparent",
        opacityCon: "0",
        visibilityCon: "hidden",

      });
      document.getElementById("LanguageSelection").style.color="#f75c4a";
      document.getElementById("LanguageSelection").style.borderColor="white";
      document.getElementById("LanguageSelection").style.backgroundColor="white";
    }
  }

  render() {
    return(
      <div className="MenuNavegacionMovil">

                  <a className="HomeButton" href="#Home" onClick={this.handleClick} style={{color: this.state.bcHome, borderLeftColor: this.state.bcHome, backgroundColor: this.state.colorHome}}>
                      <img className="logoHome"  href="#Home" onClick={this.handleClick} src={logoHome} alt="Home logo va aqui"/>
                      <h2 className="TextoHome" href="#Home" onClick={this.handleClick}>Home</h2>
                  </a>

                  <a className="ContactButton" href="#ContactUs" onClick={this.handleClick} style={{color: this.state.bcContacto, borderLeftColor: this.state.bcContacto, backgroundColor: this.state.colorContacto, opacity: this.state.opacityCon, visibility: this.state.visibilityCon}}>
                      <img className="ContactIMG"  href="#ContactUs" onClick={this.handleClick} src={this.state.click ? contactLogoNaranja:contactLogoBlanco} alt="Contact logo va aqui"/>
                      <h2 className="ContactTexto" href="#ContactUs" onClick={this.handleClick} style={{color: this.state.bcContacto}}>{this.props.t("ContactanosMovil")}</h2>
                  </a>

                  <a className="LanguageButton" href="#Idioma" onClick={this.handleClick}>
                      <img className="LanguageIMG"  onClick={this.handleClick} src={this.state.click ? languageLogoNaranja:languageLogoBlanco} alt="Language logo va aqui"/>
                      <LanguageSelector style={{color: this.state.bcContacto, borderColor: this.state.colorContacto, backgroundColor: this.state.colorContacto}}/>
                  </a>

          <div className="MenuButtonsMovil">

            <a className="Avaluos" href="#AvaluoMovil" onClick={this.handleClick} style={{color: this.state.bcAvaluo, borderLeftColor: this.state.bcAvaluo, backgroundColor: this.state.colorAvaluo}}>
              <img className="logoAvaluos"  href="#AvaluoMovil" src={this.state.click ? logoAvaluoNaranja:logoAvaluoBlanco} alt="Avaluo logo va aqui" onClick={this.handleClick} style={{color: this.state.bcAvaluo, borderLeftColor: this.state.bcAvaluo, backgroundColor: this.state.colorAvaluo}}/>
              <h2 className="TextoAvaluos" href="#AvaluoMovil" onClick={this.handleClick}>{this.props.t("Avaluo")}</h2>
            </a>

            <a className="OpinionValor" href="#OpinionMovil" onClick={this.handleClick} style={{color: this.state.bcOpinion, borderLeftColor: this.state.bcOpinion, backgroundColor: this.state.colorOpinion}}> 
              <img className="logoOpinion"  href="#OpinionMovil" src={this.state.click ? logoOpinionNaranja:logoOpinionBlanco} alt="Opinión logo va aqui" onClick={this.handleClick} style={{color: this.state.bcOpinion, borderLeftColor: this.state.bcOpinion, backgroundColor: this.state.colorOpinion}}/>
              <h2 className="TextoOpinion" href="#OpinionMovil" onClick={this.handleClick}>{this.props.t("OpinionValor")}</h2>
            </a>

            <a className='WhatsappMenu' href='https://wa.me/message/XJ6DT2VHBLPRM1' target="_blank" rel="noopener noreferrer" style={{color: this.state.bcOpinion, borderLeftColor: this.state.bcOpinion, backgroundColor: this.state.colorOpinion}}>
              <img className="Whatsappimg" href='https://wa.me/message/XJ6DT2VHBLPRM1' target="_blank" rel="noopener noreferrer" src={this.state.click ? logoWhatsappNaranja:logoWhatsappBlanco} alt="Whatsapp logo va aqui" style={{color: this.state.bcAvaluo, borderLeftColor: this.state.bcAvaluo, backgroundColor: this.state.colorAvaluo}}/>
              <h2>{this.props.t("WhatsappDesktop")}</h2>
            </a>

            <a className='AsesoriaInmo' href="#AsesoriaMovil" onClick={this.handleClick} style={{color: this.state.bcAsesoria, borderLeftColor: this.state.bcAsesoria, backgroundColor: this.state.colorAsesoria}}>
              <img className="logoAsesoria"  href="#AsesoriaMovil" src={this.state.click ? logoAsesoriaNaranja:logoAsesoriaBlanco} alt="Asesoría logo va aqui" onClick={this.handleClick} style={{color: this.state.bcAsesoria, borderLeftColor: this.state.bcAsesoria, backgroundColor: this.state.colorAsesoria}}/>
              <h2 className="TextoAsesoria" href="#AsesoriaMovil" onClick={this.handleClick}>{this.props.t("AsesoriaMovil")}</h2>
            </a>

            <a className="Levantamientos" href="#LevantamientosMovil" onClick={this.handleClick} style={{color: this.state.bcLevantamiento, borderLeftColor: this.state.bcLevantamiento, backgroundColor: this.state.colorLevantamiento}}>
              <img className="logoLevantamiento"  href="#LevantamientosMovil" src={this.state.click ? logoLevantamientoNaranja:logoLevantamientoBlanco} alt="Levantamiento logo va aqui"  onClick={this.handleClick} style={{color: this.state.bcLevantamiento, borderLeftColor: this.state.bcLevantamiento, backgroundColor: this.state.colorLevantamiento}}/>
              <h2 className="TextoLevantamiento" href="#LevantamientosMovil" onClick={this.handleClick}>{this.props.t("Croquis")}</h2>
            </a>
          </div>

        <div className='PaginaMovilContenido'>
            <div className='PaginaInicioMovil' style={{ top: this.state.topPaginaInicio}}>
                <div className='Inicio'>
                    <PaginaInicio/>
                </div>
                    <Avaluo/>
                    <OpinionValor/>
                    <AsesoriaInmo/>
                    <Levantamiento/>
                    <ContactForm/>
            </div>
            

        </div>
        

      </div>
       )
      
  }
}

export default withTranslation()(MenuNavegacionMovil);