import React from 'react';
import "./OpinionValor.css";
import { FondoPagina, FondoPaginaMovil, BalanceGIF} from './Imagenes';
import {isTablet, isDesktop} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const OpinionValor = () => {
  const { t } = useTranslation();
  return isTablet || isDesktop ?(
    <div className="PaginaOpinionValorDiv">       
        <div className="PaginaOpinionValorDesktop">
          <div className="LetreroOpinion"><h2>{t("OpinionLetrero")} </h2></div>
            <BalanceGIF/>
            <FondoPagina/>
        </div>
    </div>
    
  ):(<div className="PaginaOpinionValorDiv">       
  <div className="PaginaOpinionValor">
  <div className="LetreroOpinion" id="OpinionLetrero"><h2>{t("OpinionLetrero")}</h2></div>
  <div id="SujetoAvaluoOpinion">
  <BalanceGIF/>
  </div>
      <FondoPaginaMovil/>
      
  </div>
</div>)
}
